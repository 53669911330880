exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-stocks-joined-tsx": () => import("./../../../src/pages/stocks-joined.tsx" /* webpackChunkName: "component---src-pages-stocks-joined-tsx" */),
  "component---src-pages-stocks-tsx": () => import("./../../../src/pages/stocks.tsx" /* webpackChunkName: "component---src-pages-stocks-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-faq-template-tsx": () => import("./../../../src/templates/FAQTemplate.tsx" /* webpackChunkName: "component---src-templates-faq-template-tsx" */)
}

